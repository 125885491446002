import {
  queryOrderReviews,
  checkOrderReviewLinkStatus,
  queryOrderReviewsSummary,
  getRestaurantAMInfo,
} from '../services/orderReviews';

const BASE = 'ORDER_REVIEWs';

export const actions = {
  loadingOrderReviews: `${BASE}_LOADING_ORDER_REVIEWS`,
  loadingOrderReviewsSuccess: `${BASE}_LOADING_ORDER_REVIEWS_SUCCESS`,
  loadingOrderReviewsFailed: `${BASE}_LOADING_ORDER_REVIEWS_FAILED`,

  loadingOrderReviewsSummary: `${BASE}_LOADING_ORDER_REVIEWS_SUMMARY`,
  loadingOrderReviewsSummarySuccess: `${BASE}_LOADING_ORDER_REVIEWS_SUMMARY_SUCCESS`,
  loadingOrderReviewsSummaryFailed: `${BASE}_LOADING_ORDER_REVIEWS_SUMMARY_FAILED`,

  loadingRestaurantAMInfo: `${BASE}_LOADING_RESTAURANT_AM_INFO`,
  loadingRestaurantAMInfoSuccess: `${BASE}_LOADING_RESTAURANT_AM_INFO_SUCCESS`,
  loadingRestaurantAMInfoFailed: `${BASE}_LOADING_RESTAURANT_AM_INFO_FAILED`,
};

async function loadOrderReviewsPageData(params) {
  let response;

  try {
    response = await queryOrderReviews(params);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return response;

  let { pages: pageData } = response.meta;
  let page_size = pageData.page_size || params.page_size;
  const { current_page = 1, total_count = 0 } = pageData || {};
  const convertedData = (response.data || []).map((item) => {
    let {
      created_at,
      code: review_code,
      comment: review_content,
      order_id,
      order_number,
      score,

      // third_party_platform,
      third_party_platforms,
      images: review_content_imgs,

      guest_name: customer_name,
      restaurant_foreign_name,
      restaurant_id,
      restaurant_name,
      phone_number,
      review_type,
      id,
    } = item;

    let third_party_platform = third_party_platforms ? third_party_platforms.sort().join(',') : '';

    return {
      id,
      created_at, //unix time
      order_id,
      rating: score,
      guide_To_latform: third_party_platform || '',
      review_content: review_content || '',
      customer_name: customer_name || '',
      phone_number: phone_number || '',
      order_number,
      review_content_imgs: review_content_imgs || [],
    };
  });

  return {
    success: true,
    orderReviews: convertedData,
    pagination: {
      current: current_page,
      pageSize: page_size,
      total: total_count,
    },
  };
}

export const fetchOrderReviews = (params) => async (dispatch) => {
  dispatch({
    type: actions.loadingOrderReviews,
  });

  const response = await loadOrderReviewsPageData(params);

  if (!response.success) {
    dispatch({
      type: actions.loadingOrderReviewsFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadingOrderReviewsSuccess,
    payload: {
      orderReviews: response.orderReviews,
      pagination: response.pagination,
    },
  });
};

export const fetchOrderReviewsSummary = (params) => async (dispatch) => {
  dispatch({
    type: actions.loadingOrderReviewsSummary,
  });

  const response = await queryOrderReviewsSummary(params);

  if (!response.success) {
    dispatch({
      type: actions.loadingOrderReviewsSummaryFailed,
    });
    return;
  }
  dispatch({
    type: actions.loadingOrderReviewsSummarySuccess,
    payload: {
      orderReviewsSmmary: response.data,
    },
  });
};

export const getRestaurantAMInfoForCustomerOrderRevie = () => async (dispatch, getState) => {
  dispatch({
    type: actions.loadingRestaurantAMInfo,
  });

  let response = null;
  try {
    response = await getRestaurantAMInfo();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadingRestaurantAMInfoFailed,
    });
    return;
  }
  let { succeed, data } = response.data;
  let { restaurantId, name, email, phoneNumber, mobilePhone } = data || {};

  dispatch({
    type: actions.loadingRestaurantAMInfoSuccess,
    payload: {
      restaurantAMInfo:
        (succeed
          ? {
              restaurantId,
              name,
              email,
              phoneNumber,
              mobilePhone,
            }
          : null) || null,
    },
  });
};

import { actions as hubspotformActions } from '../actions/hubspotform';

const initialState = {
  loginReferralForm: {
    show: false,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case hubspotformActions.showLoginReferralForm: {
      return {
        ...state,
        loginReferralForm: {
          show: payload.show,
        },
      };
    }
    default:
      return state;
  }
};

import { CATALOG_SERVICE_API_PREFIX } from 'src/consts/third_party';
import { DELETE, GET, POST, PUT } from 'src/utils/request';

export function queryRestaurantRecipes({ restaurant_id, ...others }) {
  return GET(`${CATALOG_SERVICE_API_PREFIX}/api/v1/restaurants/${restaurant_id}/recipes`, others);
}

export function queryRestaurantRecipe({ restaurant_id, id, ...others }) {
  return GET(`${CATALOG_SERVICE_API_PREFIX}/api/v1/restaurants/${restaurant_id}/recipes/${id}`, others);
}

export function createRestaurantRecipe({ restaurant_id, ...others }) {
  return POST(`${CATALOG_SERVICE_API_PREFIX}/api/v1/restaurants/${restaurant_id}/recipes`, others);
}

export function updateRestaurantRecipe({ restaurant_id, id, ...others }) {
  return PUT(`${CATALOG_SERVICE_API_PREFIX}/api/v1/restaurants/${restaurant_id}/recipes/${id}`, others);
}

export function deleteRestaurantRecipe({ restaurant_id, id, ...others }) {
  return DELETE(`${CATALOG_SERVICE_API_PREFIX}/api/v1/restaurants/${restaurant_id}/recipes/${id}`, others);
}

export function queryRecipesMealInstanceAssociations({ restaurant_id, ...others }) {
  return GET(
    `${CATALOG_SERVICE_API_PREFIX}/api/v1/restaurants/${restaurant_id}/recipes_meal_instance_associations`,
    others
  );
}

export function updateRecipesMealInstanceBinding({ restaurant_id, ...others }) {
  return POST(`${CATALOG_SERVICE_API_PREFIX}/api/v1/restaurants/${restaurant_id}/recipes/binding`, others);
}

import { REVIEW_STEP, SUBMIT_RESULT_STATUS } from 'src/components/CustomerOrderReview/const';
import {
  saveCustomerOrderReviewContent as saveCustomerOrderReviewContentService,
  patchCustomerOrderReviewTo3rdPlatform,
  checkOrderReviewLinkStatusByMobilePhone,
} from '../services/customerOrderReview';

const BASE = 'CUSTOMER_ORDER_REVIEWS';

export const actions = {
  setupCustomerOrderReviewRequest: `${BASE}_SETUP_CUSTOMER_ORDER_REVIEW_INFO`,
  tryToRedirectTo3rdReiewPlatform: `${BASE}_TRY_TO_REDIRECT_3RD_REVIEW_PLATFORM`,
  updateCustomerOrderReviewStep: `${BASE}_UPDATE_CUSTOMER_ORDER_REVIEW_STEP`,
  doingOrderReviewSubmit: `${BASE}_ORDER_REVIEW_SUBMIT_IS_DOING`,

  verifyCustomerOrderReviewLinkByMobileDoing: `${BASE}_ORDER_REVIEW_LINK_VERIFY_DOING`,
  verifyCustomerOrderReviewLinkByMobileDone: `${BASE}_ORDER_REVIEW_LINK_VERIFY_DONE`,
};

export const setupCustomerOrderReviewRequest = (params) => async (dispatch) => {
  dispatch({
    type: actions.setupCustomerOrderReviewRequest,
    payload: {
      ...params,
    },
  });
};

export const updateCustomerOrderReviewStep =
  ({ nextStep, submittedReviewUUID, customerPhoneNumber, submittedScore, submittedComment, scoreThreshold }) =>
  async (dispatch) => {
    dispatch({
      type: actions.updateCustomerOrderReviewStep,
      payload: {
        nextStep,
        submittedReviewUUID,
        customerPhoneNumber,
        submittedScore,
        submittedComment,
        scoreThreshold,
      },
    });
  };

export const saveCustomerOrderReviewContent =
  ({ rateStarCount, comments, selectedPicBlobUrls }, resultCallback) =>
  async (dispatch, getState) => {
    const store = getState();
    const {
      reviewRequestInfo: { scoreThreshold, code, originalCustomerPhoneNumberOnLink },
      submittedReviewUUID,
      customerPhoneNumber,
    } = store.customerOrderReviews;

    dispatch({
      type: actions.doingOrderReviewSubmit,
      payload: {
        isDoing: true,
      },
    });

    let response = null;
    try {
      response = await saveCustomerOrderReviewContentService({
        code,
        score: rateStarCount,
        comment: comments,
        images: selectedPicBlobUrls || [],
        customerPhoneNumber: customerPhoneNumber || '', //phone_number 用户评价时留的手机号码，空表示匿名评价
      });
    } catch (e) {
      response = { success: false };
    }

    dispatch({
      type: actions.doingOrderReviewSubmit,
      payload: {
        isDoing: false,
      },
    });

    if (!response.success) {
      let statusCode = response.statusCode;
      // statusCode - 422
      //Phone number has already been taken
      //Invalid link or reviewed already
      if (resultCallback) {
        if (statusCode !== 422) {
          resultCallback(SUBMIT_RESULT_STATUS.NETWORK_ERROR);
          return;
        }
      }

      dispatch(
        updateCustomerOrderReviewStep({
          nextStep: REVIEW_STEP.REVIEW_DONE_AS_NORMAL,
        })
      );

      return;
    }

    let { uuid } = response.data;

    resultCallback && resultCallback(SUBMIT_RESULT_STATUS.SUCCESS);

    if (rateStarCount >= scoreThreshold) {
      dispatch(
        updateCustomerOrderReviewStep({
          nextStep: REVIEW_STEP.REVIEW_DONE_WILL_NVA_TO_3RD,
          submittedReviewUUID: uuid,
          submittedScore: rateStarCount,
          scoreThreshold,
          submittedComment: comments,
        })
      );
    } else {
      dispatch(updateCustomerOrderReviewStep({ nextStep: REVIEW_STEP.REVIEW_DONE_AS_NORMAL }));
    }
  };

export const updateCustomerOrderReview3rdPlatformRedirect =
  ({ platform, reviewPlatformUrl, resultCallback }) =>
  async (dispatch, getState) => {
    const store = getState();
    const { submittedReviewUUID } = store.customerOrderReviews;

    dispatch({
      type: actions.doingOrderReviewSubmit,
      payload: {
        isDoing: true,
      },
    });

    dispatch({
      type: actions.tryToRedirectTo3rdReiewPlatform,
    });

    if (!submittedReviewUUID) {
      resultCallback && resultCallback(null);
      return;
    }

    let response = null;
    try {
      response = await patchCustomerOrderReviewTo3rdPlatform({
        submittedReviewUUID,
        platform,
        url: reviewPlatformUrl,
      });
    } catch (e) {
      response = { success: false };
    }

    resultCallback && resultCallback(response);
    dispatch({
      type: actions.doingOrderReviewSubmit,
      payload: {
        isDoing: false,
      },
    });
  };

export const verifyOrderReviewLinkStatusByMobilePhone =
  ({ customerPhoneNumber }) =>
  async (dispatch, getState) => {
    const store = getState();
    const {
      reviewRequestInfo: { scoreThreshold, code },
    } = store.customerOrderReviews;
    let reviewable = false;

    dispatch({
      type: actions.verifyCustomerOrderReviewLinkByMobileDoing,
      payload: {
        isDoing: true,
      },
    });

    let response = null;
    try {
      response = await checkOrderReviewLinkStatusByMobilePhone({
        code,
        customerPhoneNumber,
      });
    } catch (e) {
      response = { success: false };
    }

    let submittedScore = 0;
    let comment = '';
    let submittedReviewUUID = '';

    if (!response.success) {
      reviewable = false;

      let thirdPartyPlatforms = [];
      let scoreThreshold = 0;

      if (response.statusCode === 409) {
        //Link reviewed already 已经提交过评价
        //已经提交的评价score
        submittedScore = response?.errors?.score || 0;
        scoreThreshold = response?.errors?.score_threshold || 5;
        submittedReviewUUID = response?.errors?.uuid || '';
        comment = response?.errors?.comment || '';
        if (submittedScore >= scoreThreshold) {
          //允许继续跳转到第3方进行评价
          //否则不允许继续跳转到第3方进行评价
          thirdPartyPlatforms = (response?.errors?.third_parties || []).map(({ platform, url }) => {
            return {
              platform,
              url,
            };
          });
        }
      }
    } else {
      ({ reviewable } = response.data);
    }

    dispatch({
      type: actions.verifyCustomerOrderReviewLinkByMobileDone,
      payload: {
        doOrderReviewEnable: reviewable,
        customerPhoneNumber: (reviewable ? customerPhoneNumber : '') || '',
        submittedScore,
        submittedComment: comment,
        submittedReviewUUID,
      },
    });
  };

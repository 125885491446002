import { salesSummaryActionEnum } from '../actions/salesSummary';
import { addMinusSignal } from 'src/utils/utils';

const initialState = {
  isSalesSummaryLoading: true,
  isSalesSummarySuccess: false,
  isSalesSummaryFailed: false,

  salesSummary: {
    revenueSummary: {},
    orderSummary: {},
    netSalesSummary: {},
    paymentsSummary: [],
    tipsSummary: [],
    discountSummary: [],
    breakdownOfGratuityAndTips: [],
    feesSummary: [],
    employeeTipsAccount: [],
    deferredSalesSummary: [],
    voidSummary: {},
    cashDrawerExpectedBalance: {},
    refundsSummary: {},
    dineInGuests: [],
    houseCash: {},
    netSalesMetrics: {},
    orderSourcesSummary: [],
    onlinePlatformsSummary: {},
    serviceTypesSummary: [],
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case salesSummaryActionEnum.salesSummaryLoading: {
      return {
        ...state,
        isSalesSummaryLoading: true,
        isSalesSummarySuccess: false,
        isSalesSummaryFailed: false,
      };
    }

    case salesSummaryActionEnum.salesSummarySuccess: {
      const { all = {}, cashDrawer = {} } = payload;
      const {
        summary = {},
        payment_breakdowns = [],
        discount_category_breakdowns = [],
        tip_type_breakdowns = [],
        taxable_breakdowns = [],
        loyalty_type_breakdowns,
        order_source_breakdowns = [],
        service_type_breakdowns = [],
        platform_breakdowns = [],
        deferred_sales_breakdowns = [],
        house_cash = {},
      } = all;

      const {
        net_sales = {},
        tax = {},
        fee = {},
        tips = {},
        comps = {},
        deferred_sales = {},
        count = {},
        gross_sales = {},
        discounts = {},
        refund = {},
        tip_out = {},
        voids = {},
        revenue = {},
        excess = {},
      } = initEmpty(summary);

      const salesSummary = {
        revenueSummary: {
          net_sales: net_sales?.amount,
          tax_amount: tax?.amount,
          grand_total: net_sales?.amount + tax?.amount,
          fee_amount: fee?.amount,
          tips: tips?.amount,
          service_tips: tips?.service_tips?.amount,
          delivery_tips: tips?.delivery_tips?.amount,
          comps: addMinusSignal(comps?.amount),
          sales_revenue: revenue?.amount,
          deferred_sales: deferred_sales?.amount,
          excess_payments: excess?.amount,
          total_revenue:
            deferred_sales?.amount === 0 && excess?.amount === 0
              ? 0
              : revenue?.amount + deferred_sales?.amount + excess?.amount,
        },
        orderSummary: {
          closed_orders: count?.total,
          open_orders: count?.open_order_total,
          canceled_orders: count?.cancel_order_total,
        },
        netSalesSummary: {
          gross_sales: gross_sales?.amount,
          discounts: addMinusSignal(discounts?.amount),
          refunds: addMinusSignal(net_sales?.refund),
          net_sales: net_sales?.amount,
        },
        paymentsSummary: payment_breakdowns ?? [],
        tipsSummary: payment_breakdowns ?? [],
        discountSummary: discount_category_breakdowns ?? [],
        breakdownOfGratuityAndTips: tip_type_breakdowns ?? [],
        feesSummary: taxable_breakdowns ?? [],
        employeeTipsAccount: {
          total_service_tips: tip_out?.tips,
          tip_out: addMinusSignal(tip_out?.out),
          tips_owed_to_employee: tip_out.owed,
        },
        deferredSalesSummary: deferred_sales_breakdowns ?? [],
        voidSummary: {
          void_amount: voids?.amount,
          void_oder_count: count?.void_order_total,
          void_item_count: count?.void_dish_total,
        },
        cashDrawerExpectedBalance: {
          cash_sales: cashDrawer?.cash_drawer?.cash_in?.cash_sales,
          cash_tips: cashDrawer?.cash_drawer?.cash_in?.cash_tips,
          miscellaneous_cash: cashDrawer?.cash_drawer?.cash_in?.miscellaneous_cash,
          starting_balance: cashDrawer?.cash_drawer?.cash_in?.starting_balance,
          cash_in_other: cashDrawer?.cash_drawer?.cash_in?.other,
          total_cash_in: cashDrawer?.cash_drawer?.cash_in?.total_cash_in,
          cash_refund: cashDrawer?.cash_drawer?.cash_out?.cash_refund,
          employee_reimbursement: cashDrawer?.cash_drawer?.cash_out?.employee_reimbursement,
          cash_out_other: cashDrawer?.cash_drawer?.cash_out?.other,
          safe_drop: cashDrawer?.cash_drawer?.cash_out?.safe_drop,
          tips_payout: cashDrawer?.cash_drawer?.cash_out?.tips_payout,
          total_cash_out: cashDrawer?.cash_drawer?.cash_out?.total_cash_out,
          balance: cashDrawer?.cash_drawer?.current_balance,
        },
        refundsSummary: {
          sales_refunds: net_sales?.refund,
          tax_refunds: tax?.refund,
          fee_refunds: fee?.refund,
          tips_refunds: tips?.refund,
          reopen_refunds: refund?.reopen_amount,
          payment_refunds: refund?.payment_amount,
          total_refunds: refund?.amount,
        },
        dineInGuests: order_source_breakdowns ?? [],
        houseCash: {
          cash_total_in_hand: house_cash?.cash_total,
          'non-delivery': house_cash?.service_house_cash?.cash_total,
          delivery: house_cash?.delivery_house_cash?.cash_total,
          'in-house_cash_service_tips': house_cash?.service_house_cash?.in_house_house_cash?.cash_tips,

          'in-house_card_service_tips': house_cash?.service_house_cash?.in_house_house_cash?.card_tips,
          'in-house_other_service_tips': house_cash?.service_house_cash?.in_house_house_cash?.other_tips,

          'in-house_card_service_tips_withholding': addMinusSignal(
            house_cash?.service_house_cash?.in_house_house_cash?.tips_withholding
          ),
          'in-house_net_sales_withholding': addMinusSignal(house_cash?.sales_withholding),
          online_service_tips: house_cash?.service_house_cash?.online_house_cash?.online_tips,
          cash_online_service_tips: house_cash?.service_house_cash?.online_house_cash?.cash_tips,
          online_service_tips_withholding: addMinusSignal(
            house_cash?.service_house_cash?.online_house_cash?.tips_withholding
          ),
          'in-house_cash_delivery_tips': house_cash?.delivery_house_cash?.in_house_house_cash?.cash_tips,
          'in-house_card_delivery_tips': house_cash?.delivery_house_cash?.in_house_house_cash?.card_tips,
          'in-house_other_delivery_tips': house_cash?.delivery_house_cash?.in_house_house_cash?.other_tips,
          'in-house_delivery_fee': house_cash?.delivery_house_cash?.in_house_house_cash?.fee,
          'in-house_card_delivery_tips_withholding': addMinusSignal(
            house_cash?.delivery_house_cash?.in_house_house_cash?.tips_withholding
          ),
          online_delivery_tips: house_cash?.delivery_house_cash?.online_house_cash?.online_tips,
          online_delivery_fee: house_cash?.delivery_house_cash?.online_house_cash?.fee,
          online_delivery_tips_withholding: addMinusSignal(
            house_cash?.delivery_house_cash?.online_house_cash?.tips_withholding
          ),
          cash_owed_to_house: house_cash?.total,
        },
        netSalesMetrics: {
          ...count,
          net_sales: net_sales?.amount,
        },
        orderSourcesSummary: order_source_breakdowns ?? [],
        onlinePlatformsSummary: platform_breakdowns ?? [],
        serviceTypesSummary: service_type_breakdowns ?? [],
      };
      return {
        ...state,
        isSalesSummaryLoading: false,
        isSalesSummarySuccess: true,
        isSalesSummaryFailed: false,
        salesSummary: salesSummary,
      };
    }

    case salesSummaryActionEnum.salesSummaryFailed: {
      return {
        ...state,
        isSalesSummaryLoading: false,
        isSalesSummarySuccess: false,
        isSalesSummaryFailed: true,
      };
    }
    default:
      return state;
  }
};

function initEmpty(data) {
  const summary = data ?? {};
  for (let key in summary) {
    summary[key] = summary[key] ?? {};
  }
  return summary;
}

import { querySalesSummary, queryCashDrawer } from '../services/salesReport';

export const salesSummaryActionEnum = {
  salesSummaryLoading: 'SALES_SUMMARY_LOADING',
  salesSummarySuccess: 'SALES_SUMMARY_SUCCESS',
  salesSummaryFailed: 'SALES_SUMMARY_FAILED',
};

export const doQuerySalesSummary = (payload) => async (dispatch) => {
  dispatch({
    type: salesSummaryActionEnum.salesSummaryLoading,
  });

  let response;

  try {
    const [all, cashDrawer] = await Promise.all([querySalesSummary(payload), queryCashDrawer(payload)]);
    if (!all.success && !cashDrawer.success) {
      response = { success: false };
    } else {
      const data = {
        all: undefined,
        cashDrawer: undefined,
      };
      if (all.success) {
        data.all = all.data;
      }
      if (cashDrawer.success) {
        data.cashDrawer = cashDrawer.data;
      }
      response = {
        success: true,
        data: data,
      };
    }
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: salesSummaryActionEnum.salesSummaryFailed,
    });
    return;
  }

  dispatch({
    type: salesSummaryActionEnum.salesSummarySuccess,
    payload: response.data,
  });
};

import { actions as recipesActions } from 'src/actions/recipe';

const initialState = {
  isLoadingRecipes: false,
  isLoadRecipesSuccess: false,
  isLoadRecipesFailed: false,

  isLoadingRecipe: false,
  isLoadRecipeSuccess: false,
  isLoadRecipeFailed: false,

  isCreatingRecipe: false,
  isCreateRecipeSuccess: false,
  isCreateRecipeFailed: false,

  isUpdatingRecipe: false,
  isUpdateRecipeSuccess: false,
  isUpdateRecipeFailed: false,

  recipes: [],
  pagination: { current: 1, pageSize: 10, total: 0 },
  recipe: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case recipesActions.loadingRecipes: {
      return { ...state, isLoadingRecipes: true, isLoadRecipesSuccess: false, isLoadRecipesFailed: false };
    }

    case recipesActions.loadRecipesSuccess: {
      return { ...state, isLoadingRecipes: false, isLoadRecipesSuccess: true, isLoadRecipesFailed: false, ...payload };
    }

    case recipesActions.loadRecipesFailed: {
      return { ...state, isLoadingRecipes: false, isLoadRecipesSuccess: true, isLoadRecipesFailed: false };
    }

    case recipesActions.loadingRecipe: {
      return { ...state, isLoadingRecipe: true, isLoadRecipeSuccess: false, isLoadRecipeFailed: false };
    }

    case recipesActions.loadRecipeSuccess: {
      return {
        ...state,
        isLoadingRecipe: false,
        isLoadRecipeSuccess: true,
        isLoadRecipeFailed: false,
        recipe: payload,
      };
    }

    case recipesActions.loadRecipeFailed: {
      return { ...state, isLoadingRecipe: false, isLoadRecipeSuccess: true, isLoadRecipeFailed: false };
    }

    case recipesActions.creatingRecipe: {
      return { ...state, isCreatingRecipe: true, isCreateRecipeSuccess: false, isCreateRecipeFailed: false };
    }

    case recipesActions.createRecipeSuccess: {
      return { ...state, isCreatingRecipe: false, isCreateRecipeSuccess: true, isCreateRecipeFailed: false };
    }

    case recipesActions.createRecipeFailed: {
      return { ...state, isCreatingRecipe: false, isCreateRecipeSuccess: false, isCreateRecipeFailed: true };
    }

    case recipesActions.updatingRecipe: {
      return { ...state, isUpdatingRecipe: true, isUpdateRecipeSuccess: false, isUpdateRecipeFailed: false };
    }

    case recipesActions.updateRecipeSuccess: {
      return { ...state, isUpdatingRecipe: false, isUpdateRecipeSuccess: true, isUpdateRecipeFailed: false };
    }

    case recipesActions.updateRecipeFailed: {
      return { ...state, isUpdatingRecipe: false, isUpdateRecipeSuccess: false, isUpdateRecipeFailed: true };
    }

    default: {
      return state;
    }
  }
};

import { DELETE, GET, POST } from 'src/utils/request';
import { API_PREFIX } from 'src/consts';

export function getCustomizationDishPreference(customizedPropertyId) {
  return GET(`${API_PREFIX}/v1/customization/${customizedPropertyId}/dish/preferences`);
}

export function getAllCustomizationDishPreferences() {
  return GET(`${API_PREFIX}/v1/customized/dish/preferences`);
}

export function checkIfDishPreferenceOptionsIsApplied(dishPreferencesOptions) {
  return POST(`${API_PREFIX}/v1/customized/dish/preferences/check`, {
    dish_preference_options: dishPreferencesOptions,
  });
}

export function updateCustomizationDishPreference({ customizedPropertyId, payload }) {
  return POST(`${API_PREFIX}/v1/customization/${customizedPropertyId}/dish/preferences`, payload);
}

import { queryAppConfigs } from 'src/services/appconfig';
import axios from 'axios';

const ASSETS_PREFIX = process.env.ASSETS_PREFIX || '';
const AM_EMAIL_WHITELIST_TO_DOWNLOAD_REPORT = process.env.AM_EMAIL_WHITELIST_TO_DOWNLOAD_REPORT || '';
const filePath = `${ASSETS_PREFIX}/assets/report/${AM_EMAIL_WHITELIST_TO_DOWNLOAD_REPORT}`;

const BASE = 'APP_CONFIG';

export const actions = {
  loadingAppConfigs: `${BASE}_LOADING_APP_CONFIGS`,
  loadAppConfigsSuccess: `${BASE}_LOAD_APP_CONFIGS_SUCCESS`,
  loadAppConfigsFailed: `${BASE}_LOAD_APP_CONFIGS_FAILED`,
  loadingAMWhitelist: `${BASE}_LOADING_AM_WHITELIST`,
  loadAMWhitelistDone: `${BASE}_LOAD_AM_WHITELIST_DONE`,
};

export const fetchAppConfigs = (params, callback) => async (dispatch) => {
  dispatch({
    type: actions.loadingAppConfigs,
  });

  let response;

  try {
    response = await queryAppConfigs(params);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) {
    dispatch({
      type: actions.loadAppConfigsFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadAppConfigsSuccess,
    payload: { appConfigs: response.data },
  });
};

export const fetchAMWhitelist = () => async (dispatch, getState) => {
  const { isAMWhitelistLoaded, isLoadingAMWhitelist } = getState().appConfig;
  if (isLoadingAMWhitelist || isAMWhitelistLoaded) return;

  dispatch({ type: actions.loadingAMWhitelist });

  let response;
  let hasError = false;

  try {
    response = await axios.get(`${filePath}?ts=${Date.now()}`, {
      responseType: 'text',
      transformResponse: (data) => data,
    });
  } catch (e) {
    hasError = true;
  }

  let AMWhitelist = [];

  if (!hasError && typeof response.data === 'string') {
    AMWhitelist = (response.data || '').split(',');
  }

  dispatch({
    type: actions.loadAMWhitelistDone,
    payload: {
      AMWhitelist,
      isAMWhitelistLoaded: !hasError,
    },
  });
};

import { actions as customerOrderReviewsActions } from '../actions/customerOrderReviews';
import { REVIEW_STEP } from 'src/components/CustomerOrderReview/const';
const initialState = {
  reviewRequestInfo: null,
  reviewStep: null,
  submittedReviewUUID: undefined, //提交的品论uuid，在需要跳转第3方评论时需要
  customerPhoneNumber: undefined, //空表示匿名评价
  originalCustomerPhoneNumberOnLink: undefined, //评论link中带的customer phone number
  redirectTo3rdPlatformDone: undefined,
  isLoading: false,
  submitResultStatus: undefined,

  submittedScore: undefined,
  submittedComment: undefined,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case customerOrderReviewsActions.setupCustomerOrderReviewRequest: {
      let {
        submittedReviewUUID,
        reviewable,
        customerPhoneNumber,
        submittedComment,
        submittedScore,
        scoreThreshold,
        thirdPartyPlatforms,
      } = payload;
      let reviewStep = state.reviewStep;
      if (!reviewable) {
        if (submittedScore && thirdPartyPlatforms && thirdPartyPlatforms.length !== 0) {
          if (submittedScore >= scoreThreshold) {
            //允许继续到第3方评论
            reviewStep = REVIEW_STEP.REVIEW_DONE_WILL_NVA_TO_3RD;
          } else {
            //不允许继续到第3方评论
            reviewStep = REVIEW_STEP.REVIEW_DONE_AS_NORMAL;
          }
        } else {
          reviewStep = REVIEW_STEP.REVIEW_DONE_AS_NORMAL;
        }
      } else if (customerPhoneNumber) {
        reviewStep = REVIEW_STEP.ENTER_RATE_STAR;
      } else {
        reviewStep = REVIEW_STEP.ENTER_MOBILE_NUMBER;
      }

      return {
        ...state,
        redirectTo3rdPlatformDone: undefined,
        submittedReviewUUID,
        submittedComment,
        submittedScore,
        reviewRequestInfo: {
          ...payload,
        },
        reviewStep: reviewStep,
        originalCustomerPhoneNumberOnLink: customerPhoneNumber,
        ...(!customerPhoneNumber
          ? {}
          : {
              customerPhoneNumber,
            }),
      };
    }
    case customerOrderReviewsActions.updateCustomerOrderReviewStep: {
      let { nextStep, customerPhoneNumber, submittedReviewUUID, submittedScore, submittedComment } = payload;
      return {
        ...state,
        reviewStep: nextStep,
        ...(customerPhoneNumber === undefined
          ? {}
          : {
              customerPhoneNumber: customerPhoneNumber || '',
            }),
        ...(submittedReviewUUID == undefined
          ? {}
          : {
              submittedReviewUUID: submittedReviewUUID || '',
            }),
        ...(submittedScore == undefined
          ? {}
          : {
              submittedScore: submittedScore || 0,
            }),
        ...(submittedComment == undefined
          ? {}
          : {
              submittedComment: submittedComment || '',
            }),
      };
    }
    case customerOrderReviewsActions.tryToRedirectTo3rdReiewPlatform: {
      return {
        ...state,
        redirectTo3rdPlatformDone: true,
      };
    }
    case customerOrderReviewsActions.doingOrderReviewSubmit: {
      return {
        ...state,
        isLoading: payload.isDoing,
      };
    }
    case customerOrderReviewsActions.verifyCustomerOrderReviewLinkByMobileDoing: {
      return {
        ...state,
        isLoading: payload.isDoing,
      };
    }

    case customerOrderReviewsActions.verifyCustomerOrderReviewLinkByMobileDone: {
      let { doOrderReviewEnable, customerPhoneNumber, submittedComment, submittedScore, submittedReviewUUID } = payload;
      let nextStep = doOrderReviewEnable ? REVIEW_STEP.ENTER_RATE_STAR : REVIEW_STEP.REVIEW_DONE_AS_NORMAL;
      if (!doOrderReviewEnable) {
        if (
          submittedScore &&
          submittedScore >= state.reviewRequestInfo?.scoreThreshold &&
          submittedComment &&
          state.reviewRequestInfo?.thirdPartyPlatforms &&
          state.reviewRequestInfo?.thirdPartyPlatforms.length !== 0
        ) {
          nextStep = REVIEW_STEP.REVIEW_DONE_WILL_NVA_TO_3RD;
        }
      }
      return {
        ...state,
        isLoading: false,
        reviewStep: nextStep,
        submittedReviewUUID,
        customerPhoneNumber: (doOrderReviewEnable ? customerPhoneNumber : '') || '',
        reviewRequestInfo: {
          ...state.reviewRequestInfo,
        },
        ...(submittedScore == undefined
          ? {}
          : {
              submittedScore: submittedScore || 0,
            }),
        ...(submittedComment == undefined
          ? {}
          : {
              submittedComment: submittedComment || '',
            }),
      };
    }

    default:
      return state;
  }
};

const BASE = 'HUBSPOT';
import { setCookie, getCookie, KEYS } from 'src/store/storage';
export const actions = {
  showLoginReferralForm: `${BASE}_showLoginReferralForm`,
};

export const popupLoginReferralForm = () => async (dispatch) => {
  if (!getCookie(KEYS.hubspotLoginReferralForm)) {
    dispatch({
      type: actions.showLoginReferralForm,
      payload: { show: true },
    });
  }
};

export const closeLoginReferralForm = () => async (dispatch) => {
  setCookie({ [KEYS.hubspotLoginReferralForm]: 'off' });
  dispatch({
    type: actions.showLoginReferralForm,
    payload: { show: false },
  });
};

import {
  createRestaurantRecipe,
  queryRestaurantRecipe,
  queryRestaurantRecipes,
  updateRestaurantRecipe,
} from 'src/services/recipe';

const BASE = 'MENU';

export const actions = {
  loadingRecipes: `${BASE}_LOADING_RECIPES`,
  loadRecipesSuccess: `${BASE}_LOAD_RECIPES_SUCCESS`,
  loadRecipesFailed: `${BASE}_LOAD_RECIPES_FAILED`,

  loadingRecipe: `${BASE}_LOADING_RECIPES`,
  loadRecipeSuccess: `${BASE}_LOAD_RECIPES_SUCCESS`,
  loadRecipeFailed: `${BASE}_LOAD_RECIPES_FAILED`,

  creatingRecipe: `${BASE}_CREATING_RECIPE`,
  createRecipeSuccess: `${BASE}_CREATE_RECIPE_SUCCESS`,
  createRecipeFailed: `${BASE}_CREATE_RECIPE_FAILED`,

  updatingRecipe: `${BASE}_UPDATING_RECIPE`,
  updateRecipeSuccess: `${BASE}_UPDATE_RECIPE_SUCCESS`,
  updateRecipeFailed: `${BASE}_UPDATE_RECIPE_FAILED`,
};

export const fetchRestaurantRecipes = (params) => async (dispatch) => {
  dispatch({ type: actions.loadingRecipes });

  let response;

  try {
    response = await queryRestaurantRecipes(params);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadRecipesFailed });
    return;
  }

  const { data, page: pageData } = response.data || {};
  const { page = 1, page_size = 10, total = 0 } = pageData || {};

  dispatch({
    type: actions.loadRecipesSuccess,
    payload: {
      recipes: (data.recipes || []).map((recipe) => ({
        ...recipe,
        recipe_customized_properties: (recipe.recipe_customized_properties || []).sort(
          (a, b) => a.sequence - b.sequence
        ),
      })),
      pagination: { current: page, pageSize: page_size, total },
    },
  });
};

export const fetchRestaurantRecipe = (params, callback) => async (dispatch) => {
  dispatch({ type: actions.loadingRecipe });

  let response;

  try {
    response = await queryRestaurantRecipe(params);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadRecipeFailed });
    return;
  }

  const recipes = response.data || {};

  dispatch({ type: actions.loadRecipeSuccess, payload: recipes });

  typeof callback === 'function' && callback(recipes);
};

export const doCreateRestaurantRecipe = (data, callback) => async (dispatch) => {
  dispatch({ type: actions.creatingRecipe });

  let response;

  try {
    response = await createRestaurantRecipe(data);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) {
    dispatch({ type: actions.createRecipeFailed });
    return;
  }

  dispatch({ type: actions.createRecipeSuccess });
};

export const doUpdateRestaurantRecipe = (data, callback) => async (dispatch) => {
  dispatch({ type: actions.updatingRecipe });

  let response;

  try {
    response = await updateRestaurantRecipe(data);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) {
    dispatch({ type: actions.updateRecipeFailed });
    return;
  }

  dispatch({ type: actions.updateRecipeSuccess });
};

export const REVIEW_STEP = {
  ENTER_MOBILE_NUMBER: 'enter_mobile_number',
  ENTER_RATE_STAR: 'entry_rate_star',
  REVIEW_DONE_WILL_NVA_TO_3RD: 'will_nav_to_3red_after_done',
  REVIEW_DONE_AS_NORMAL: 'fininshed_normally',
};

export const SUBMIT_RESULT_STATUS = {
  SUCCESS: 'success - 200',
  NETWORK_ERROR: 'Request failed - not (200,422)',
  REVIEW_REVIEWD_ALERDY: 'Invalid link or reviewed already - 422',
};

export const CHOWBUS_REVIEW_RATE_TOP_ICON = `${process.env.ASSETS_PREFIX}/assets/review/review_rate.png`;
export const CHOWBUS_REVIEW_STAR_ON_ICON = `${process.env.ASSETS_PREFIX}/assets/review/review_star_on.png`;
export const CHOWBUS_REVIEW_STAR_OFF_ICON = `${process.env.ASSETS_PREFIX}/assets/review/review_star_off.png`;
export const CHOWBUS_REVIEW_THANKS_ICON_ICON = `${process.env.ASSETS_PREFIX}/assets/review/review_thanks.png`;
export const CHOWBUS_REVIEW_3RD_TARGET_YELP = `${process.env.ASSETS_PREFIX}/assets/review/review_target_yelp_logo.png`;
export const CHOWBUS_REVIEW_3RD_TARGET_GOOGLE = `${process.env.ASSETS_PREFIX}/assets/review/review_target_google_logo.png`;

export const CHOWBUS_REVIEW_MAX_STAR_COUNT = 5;

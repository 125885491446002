import { DELETE, GET, POST } from '../utils/request';
import { API_PREFIX, CLIENT_ID, LOYALTY_API_PREFIX } from '../consts';

export function queryOrderReviews({
  //   search_name,
  time_from = '',
  time_to = '',
  page = 1,
  page_size = 10,
  scores,
  third_party_platforms,
  // content,
  // guest_name,
  // phone,
  // order_number,
  keyword_type,
  keyword,
}) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/review/v1/order_reviews`,
    {
      time_from,
      time_to,
      page,
      per_page: page_size,
      scores,
      third_party_platforms,
      keyword_type,
      keyword,
    },
    {
      parseAsJSONApi: true,
      silence: true,
    }
  );
}

export function queryOrderReviewsSummary({ time_from = '', time_to = '' }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/review/v1/order_reviews/summary`,
    {
      time_from,
      time_to,
    },
    {
      parseAsJSONApi: false,
      silence: true,
    }
  );
}

export function getRestaurantAMInfo() {
  return GET(
    `${LOYALTY_API_PREFIX}/api/review/v1/order_reviews/restaurant_am`,
    {},
    {
      silence: true,
      parseAsJSONApi: false,
      showLoading: false,
      noNeedLogin: false,
    }
  );
}

import { actions as menuBuilderActions } from 'src/actions/menuBuilder';

const initialState = {
  isLoadingMenuTree: false,
  isLoadMenuTreeSuccess: false,
  isLoadMenuTreeFailed: false,

  menusGroupedByContract: {},
  menuCacheMap: {},
  categoryCacheMap: {},
  menuChannels: [],

  isSortingMenus: false,
  isSortMenusFailed: false,
  isSortingCategories: false,
  isSortCategoriesFailed: false,

  isLoadingMenuChannels: false,
  isLoadedMenuChannels: false,

  menuPathBeforeSearch: null,

  isLoadingDeliveryChannels: false,
  isLoadDeliveryChannelsSuccess: false,
  isLoadDeliveryChannelsFailed: false,
  deliveryChannels: [],
  platformsOfMenus: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case menuBuilderActions.loadingMenuTree: {
      return {
        ...state,
        isLoadingMenuTree: true,
        isLoadMenuTreeSuccess: false,
        isLoadMenuTreeFailed: false,
      };
    }

    case menuBuilderActions.loadMenuTreeSuccess: {
      return {
        ...state,
        ...payload,
        isLoadingMenuTree: false,
        isLoadMenuTreeSuccess: true,
        isLoadMenuTreeFailed: false,
      };
    }

    case menuBuilderActions.loadMenuTreeFailed: {
      return {
        ...state,
        isLoadingMenuTree: false,
        isLoadMenuTreeSuccess: false,
        isLoadMenuTreeFailed: true,
      };
    }

    case menuBuilderActions.sortingMenus: {
      return {
        ...state,
        isSortingMenus: true,
        isSortMenusFailed: false,
      };
    }

    case menuBuilderActions.sortMenusSuccess: {
      return {
        ...state,
        isSortingMenus: false,
        isSortMenusFailed: false,
        menusGroupedByContract: payload,
      };
    }

    case menuBuilderActions.sortMenusFailed: {
      return {
        ...state,
        isSortingMenus: false,
        isSortMenusFailed: true,
      };
    }

    case menuBuilderActions.sortingCategories: {
      return {
        ...state,
        isSortingCategories: true,
        isSortCategoriesFailed: false,
      };
    }

    case menuBuilderActions.sortCategoriesSuccess: {
      return {
        ...state,
        menuCacheMap: payload,
        isSortingCategories: false,
        isSortCategoriesFailed: false,
      };
    }

    case menuBuilderActions.sortCategoriesFailed: {
      return {
        ...state,
        isSortingCategories: false,
        isSortCategoriesFailed: true,
      };
    }

    case menuBuilderActions.menuCacheChanged: {
      return {
        ...state,
        menuCacheMap: payload,
      };
    }

    case menuBuilderActions.categoryCacheChanged: {
      return {
        ...state,
        categoryCacheMap: payload,
      };
    }

    case menuBuilderActions.updateMenuPathBeforeSearch: {
      return {
        ...state,
        menuPathBeforeSearch: payload,
      };
    }

    case menuBuilderActions.loadingMenuChannels: {
      return {
        ...state,
        isLoadingMenuChannels: true,
      };
    }

    case menuBuilderActions.loadMenuChannelsDone: {
      const { success, data } = payload;

      return {
        ...state,
        isLoadingMenuChannels: false,
        isLoadedMenuChannels: success,
        menuChannels: data,
      };
    }

    case menuBuilderActions.loadingDeliveryChannels: {
      return {
        ...state,
        isLoadingDeliveryChannels: true,
        isLoadDeliveryChannelsSuccess: false,
        isLoadDeliveryChannelsFailed: false,
      };
    }

    case menuBuilderActions.loadDeliveryChannelsFailed: {
      return {
        ...state,
        isLoadingDeliveryChannels: false,
        isLoadDeliveryChannelsSuccess: false,
        isLoadDeliveryChannelsFailed: true,
        deliveryChannels: [],
        platformsOfMenus: {},
      };
    }

    case menuBuilderActions.loadDeliveryChannelsSuccess: {
      return {
        ...state,
        isLoadingDeliveryChannels: false,
        isLoadDeliveryChannelsSuccess: true,
        isLoadDeliveryChannelsFailed: false,
        ...payload,
      };
    }

    case menuBuilderActions.refreshMenuInfo: {
      return {
        ...state,
        menuCacheMap: payload,
      };
    }

    default: {
      return state;
    }
  }
};

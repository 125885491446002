import { getAllCustomizationDishPreferences } from 'src/services/customizationDishPreference';
import { getDishPreferences } from '../services/dishPreference';

const BASE = 'DISH_PREFERENCE';

export const actions = {
  loadingDishPreferences: `${BASE}_LOADING_DISH_PREFERENCES`,
  loadDishPreferencesSuccess: `${BASE}_LOAD_DISH_PREFERENCES_SUCCESS`,
  loadDishPreferencesFailed: `${BASE}_LOAD_DISH_PREFERENCES_FAILED`,
  loadedCustomizationDishPreferencesMapping: `${BASE}_LOADED_CUSTOMIZATION_DISH_PREFERENCES_MAPPING`,
};

export const fetchDishPreferences = () => async (dispatch, getState) => {
  const { dishPreference } = getState();
  if (dishPreference?.isLoadingDishPreferences) return;

  dispatch({ type: actions.loadingDishPreferences });

  let response;

  try {
    response = await getDishPreferences();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.loadDishPreferencesFailed });
    return;
  }

  dispatch({
    type: actions.loadDishPreferencesSuccess,
    payload: response.data,
  });
};

export const getCustomizactionDishPreferencesMapping = () => async (dispatch) => {
  let response;

  try {
    response = await getAllCustomizationDishPreferences();
  } catch (e) {
    response = { success: false };
  }

  const customizationDishPreferenceMapping = {};
  if (response.success) {
    const customized_options = response.data || [];
    if (customized_options.length) {
      customized_options.forEach((optionItem) => {
        const { customized_option_id, category_list } = optionItem;
        category_list.forEach((categoryItem) => {
          if (!customizationDishPreferenceMapping[categoryItem.id]) {
            customizationDishPreferenceMapping[categoryItem.id] = [];
          }
          customizationDishPreferenceMapping[categoryItem.id].push(customized_option_id);
        });
      });
    }
  }

  dispatch({
    type: actions.loadedCustomizationDishPreferencesMapping,
    payload: customizationDishPreferenceMapping,
  });
};

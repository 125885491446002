import { GET } from '../utils/request';
import { API_PREFIX, SALES_REPORT_API_PREFIX } from '../consts';
import { getReportServiceApiPrefix } from '../utils/report';

export const URL_SALES_REPORT = `${getReportServiceApiPrefix()}/v2/earning/report`;

export function querySalesReport({
  start_at,
  end_at,
  employee_id,
  group_by,
  show_hours,
  order_sources,
  service_types,
  platforms,
  role_id,
  department_id,
  sales_category_group_by,
}) {
  return GET(URL_SALES_REPORT, {
    start_at,
    end_at,
    employee_id,
    group_by,
    show_hours,
    order_sources,
    service_types,
    platforms,
    role_id,
    department_id,
    sales_category_group_by,
  });
}

export function queryCashSessions(data) {
  return GET(`${API_PREFIX}/v1/cash_sessions`, data);
}

export function queryCashFlow({ page, page_size, start_at, end_at, device_id }) {
  return GET(`${API_PREFIX}/v1/cash_flows`, {
    page,
    page_size,
    start_at,
    end_at,
    device_id,
    need_total: true,
  });
}

export function queryCostPlusPayoutData({ start_at, end_at, is_daily_cost_plus_open }) {
  return GET(`${getReportServiceApiPrefix()}/v2/earning/costplus`, {
    start_at,
    end_at,
    is_daily_cost_plus_open,
  });
}

export function queryNonCostPlusPayoutData({ start_at, end_at }) {
  return GET(`${getReportServiceApiPrefix()}/v2/earning/payout/summary`, {
    start_at,
    end_at,
  });
}

export function querySalesBreakdown({
  start_at,
  end_at,
  menu_ids,
  category_ids,
  order_sources,
  service_types,
  platforms,
  employee_id,
  category_names,
}) {
  return GET(`${getReportServiceApiPrefix()}/v1/earning/sales-breakdown`, {
    start_at,
    end_at,
    menu_ids,
    category_ids,
    order_sources,
    service_types,
    platforms,
    employee_id,
    category_names,
  });
}

export function getAvailablePayoutSummary({ restaurant_id, start_at, end_at }) {
  return GET(`${getReportServiceApiPrefix()}/v2/earning/payout/available/summary`, {
    restaurant_id,
    start_at,
    end_at,
  });
}

//新报表 sales summary
export function querySalesSummary(payload) {
  return GET(`${SALES_REPORT_API_PREFIX}/sales_report/all`, payload);
}

export function queryCashDrawer(payload) {
  return GET(`${SALES_REPORT_API_PREFIX}/sales_report/cash_drawer`, payload);
}

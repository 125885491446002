import { POST, PATCH, GET } from '../utils/request';
import { CLIENT_ID, LOYALTY_API_PREFIX, MONOLITH_API_PREFIX, LOYALTY_API_PREFIX_SERVERSIDE } from '../consts';

function checkOrderReviewLinkStatus({ code }) {
  const isServer = typeof window === 'undefined';
  let domainPath = '';
  if (isServer) {
    domainPath = LOYALTY_API_PREFIX_SERVERSIDE;
  } else {
    domainPath = LOYALTY_API_PREFIX;
  }
  return GET(
    `${domainPath}/api/review/v1/order_reviews/${code}/status`,
    {},
    {
      silence: true,
      showLoading: false,
      noNeedLogin: true,
      // globalSilence: true,
    }
  );
}

export function saveCustomerOrderReviewContent({ code, score, comment, images, customerPhoneNumber }) {
  return POST(
    `${LOYALTY_API_PREFIX}/api/review/v1/order_reviews`,
    {
      code,
      review: {
        score,
        comment,
        images: images || [],
        third_party_platform: [],
        phone_number: customerPhoneNumber || '', //phone_number 用户评价时留的手机号码，空表示匿名评价？
      },
    },
    {
      silence: true,
      globalSilence: true,
      showLoading: false,
      noNeedLogin: true,
    }
  );
}

export function patchCustomerOrderReviewTo3rdPlatform({ submittedReviewUUID, platform, url }) {
  return PATCH(
    `${LOYALTY_API_PREFIX}/api/review/v1/order_reviews/${submittedReviewUUID}/append_third_party`,
    {
      third_party_platform: platform,
    },
    {
      silence: true,
      showLoading: false,
      noNeedLogin: true,
    }
  );
}

export function uploadOrderReviewPhoto({ formData, uploadImageToken, reviewCode, source = '' }) {
  return POST(`${MONOLITH_API_PREFIX}/api/order_review/v1/uploads?source=${source}`, formData, {
    noNeedLogin: true,
    refreshToken: async () => {
      //refresh token after 401,
      let { uploadImageToken } = await checkOrderReviewLinkUsable({ code: reviewCode });
      return uploadImageToken || '';
    },
    headers: {
      'client-id': CLIENT_ID,
      'service-region-id': 1,
      Authorization: `Bearer ${uploadImageToken}`,
    },
  });
}

export async function checkOrderReviewLinkUsable({ code }) {
  let response;
  let resultData = {
    reviewable: false,
    restaurnt_name: '',
    restaurnt_foreign_name: '',
    score_threshold: 1,
    third_party_platform: '',
  };

  try {
    response = await checkOrderReviewLinkStatus({ code });
  } catch (e) {
    response = {
      success: false,
      data: response.data || {
        reviewable: false,
        message: '',
      },
    };
  }

  let {
    reviewable,
    message,
    restaurant_name,
    restaurant_foreign_name,
    phone_number,
    score_threshold,
    third_parties,
    token,
  } = response.data || {
    reviewable: false,
    score_threshold: 1,
    third_parties: [],
    phone_number: '',
    restaurnt_foreign_name: '',
    restaurnt_name: '',
    message: '',
    token: '',
  };

  //404, { reviewable: false, message: 'Invalid link.' }
  //409, { reviewable: false, message: 'Link reviewed already.', score: 3, third_parties: [xxx] }
  if (response.statusCode === 409) {
    //Link reviewed already
    //但是允许继续天跳转到第方进行评价
    reviewable = false;
  } else if (response.statusCode === 404) {
    reviewable;
  }
  if (!response.success) {
    ({ reviewable, message, third_parties } = response.errors);

    let thirdPartyPlatforms = [];
    let scoreThreshold = 5;
    let submittedScore = 0;
    let comment = '';
    let submittedReviewUUID = '';

    if (response.statusCode === 409) {
      //Link reviewed already 已经提交过评价
      //已经提交的评价score
      submittedScore = response?.errors?.score || 0;
      scoreThreshold = response?.errors?.score_threshold || 5;
      comment = response?.errors?.comment || '';
      submittedReviewUUID = response?.errors?.uuid || '';
      if (submittedScore >= scoreThreshold) {
        //允许继续跳转到第3方进行评价
        //否则不允许继续跳转到第3方进行评价
        thirdPartyPlatforms = (response?.errors?.third_parties || []).map(({ platform, url }) => {
          return {
            platform,
            url,
          };
        });
      }
    }

    resultData = {
      reviewable,
      message,
      restaurant: {
        name: '',
        foreignName: '',
      },
      customerPhoneNumber: '',
      submittedScore,
      scoreThreshold,
      thirdPartyPlatforms,
      submittedComment: comment,
      uploadImageToken: '',
      submittedReviewUUID,
    };
  } else {
    resultData = {
      submittedReviewUUID: '',
      reviewable,
      uploadImageToken: token || '',
      message: message || '',
      restaurant: {
        name: restaurant_name || '',
        foreignName: restaurant_foreign_name || '',
      },
      scoreThreshold: score_threshold || 5,
      customerPhoneNumber: phone_number || '',
      thirdPartyPlatforms: (third_parties || []).map(({ platform, url }) => {
        return {
          platform,
          url,
        };
      }),
    };
  }

  return {
    ...resultData,
    code,
  };
}

export function checkOrderReviewLinkStatusByMobilePhone({ code, customerPhoneNumber }) {
  return GET(
    `${LOYALTY_API_PREFIX}/api/review/v1/order_reviews/${code}/can_submit_review`,
    {
      phone_number: customerPhoneNumber,
    },
    {
      silence: true,
      showLoading: false,
      noNeedLogin: true,
    }
  );
}

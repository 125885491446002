import { actions as orderReviewsActions } from '../actions/orderReviews';

const initialState = {
  isLoadingOrderReviews: false,
  isLoadingOrderReviewsSuccess: false,
  isLoadingOrderReviewsFailed: false,

  orderReviews: [],
  pagination: { current: 1, pageSize: 10, total: 0 },

  isLoadingOrderReviewsSummary: false,
  isLoadingOrderReviewsSummarySuccess: false,
  isLoadingOrderReviewsSummaryFailed: false,
  orderReviewsSmmary: null,

  isLoadingRestauantAMInfo: false,
  isLoadingRestauantAMInfoSuccess: false,
  isLoadingRestauantAMInfoFailed: false,
  restaurantAMInfo: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case orderReviewsActions.loadingOrderReviews: {
      return {
        ...state,
        isLoadingOrderReviews: true,
        isLoadingOrderReviewsSuccess: false,
        isLoadingOrderReviewsFailed: false,
      };
    }

    case orderReviewsActions.loadingOrderReviewsSuccess: {
      return {
        ...state,
        isLoadingOrderReviews: false,
        isLoadingOrderReviewsSuccess: true,
        isLoadingOrderReviewsFailed: false,
        ...payload,
      };
    }

    case orderReviewsActions.loadingOrderReviewsFailed: {
      return {
        ...state,
        isLoadingOrderReviews: false,
        isLoadingOrderReviewsSuccess: false,
        isLoadingOrderReviewsFailed: true,
      };
    }

    case orderReviewsActions.loadingOrderReviewsSummary: {
      return {
        ...state,
        isLoadingOrderReviewsSummary: true,
        isLoadingOrderReviewsSummarySuccess: false,
        isLoadingOrderReviewsSummaryFailed: false,
      };
    }

    case orderReviewsActions.loadingOrderReviewsSummarySuccess: {
      return {
        ...state,
        isLoadingOrderReviewsSummary: false,
        isLoadingOrderReviewsSummarySuccess: true,
        isLoadingOrderReviewsSummaryFailed: false,
        ...payload,
      };
    }

    case orderReviewsActions.loadingOrderReviewsSummaryFailed: {
      return {
        ...state,
        isLoadingOrderReviewsSummary: false,
        isLoadingOrderReviewsSummarySuccess: false,
        isLoadingOrderReviewsSummaryFailed: true,
      };
    }

    case orderReviewsActions.loadingRestaurantAMInfo: {
      return {
        ...state,
        isLoadingRestauantAMInfo: true,
        isLoadingRestauantAMInfoSuccess: false,
        isLoadingRestauantAMInfoFailed: false,
      };
    }

    case orderReviewsActions.loadingRestaurantAMInfoSuccess: {
      return {
        ...state,
        isLoadingRestauantAMInfo: false,
        isLoadingRestauantAMInfoSuccess: true,
        isLoadingRestauantAMInfoFailed: false,
        restaurantAMInfo: payload.restaurantAMInfo,
      };
    }

    case orderReviewsActions.loadingRestaurantAMInfoFailed: {
      return {
        ...state,
        isLoadingRestauantAMInfo: false,
        isLoadingRestauantAMInfoSuccess: false,
        isLoadingRestauantAMInfoFailed: true,
      };
    }

    default:
      return state;
  }
};
